import React, {lazy} from 'react';
import { Route, Switch } from 'react-router-dom';
import Banner from '../../components/InfoPages/Banner';
import WaitingComponent from '../../components/WaitingComponent/WaitingComponent';
import './style.css';
import SubPages from './SubPages';

const LandingLazy = lazy(()=>import('./Landing/Landing'));

export default () => (
  <>
    <Switch>
      <Route exact path="/" component={WaitingComponent(LandingLazy)} />
      <Route path="/" component={SubPages} />
    </Switch>
    {/* <Banner />     */}
  </>
)
