import axios from '../../config/axios-instance';
import api from '../../config/api';
import { showNotification } from "./ui";
import {
  GET_RESULTS_REQUEST,
  GET_RESULTS_SUCCESS,
  GET_RESULTS_FAIL
} from "./actionTypes";

const getResultsRequest = () => {
  return {
    type: GET_RESULTS_REQUEST
  };
};
const getResultsSuccess = results => {
  return {
    type: GET_RESULTS_SUCCESS,
    results
  };
};
const getResultsFail = () => {
  return {
    type: GET_RESULTS_FAIL
  };
};

export const getResults = (user_id) => dispatch => {
  dispatch(getResultsRequest());
  return axios
    .get(api.get_results, { params: { test_key: "inventory", user_id } })
    .then(response => {
      dispatch(
        showNotification({ message: "Get results successful", positive: true })
      );
      dispatch(getResultsSuccess(response.data));
      return response.data;
    })
    .catch(error => {
      dispatch(getResultsFail());
      dispatch(
        showNotification({ message: "Get result failed", negative: true })
      );
    });
};
