import React, { Component } from "react";
import { Switch, Route, withRouter } from "react-router-dom";
import { withI18n } from "react-i18next";
import { connect } from "react-redux";
import { hideNotification } from "./store/actions";
import Login from "./containers/Login/Login";
import Message from "./components/Message/Message";
import Layout from "./hoc/Layout/Layout";
import Main from "./containers/Main/Main";
import Test from "./containers/Test/Test";
import Report from "./containers/Report/Report";
import SchoolReport from "./containers/SchoolReport/SchoolReport";
import Policy from "./components/Policy";
import Profession from "./containers/Profession";
import { Transition, List, Modal, Header, Image } from "semantic-ui-react";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute";
import ResetPassword from "./containers/Login/ResetPassword";
import InfoPages from "./containers/InfoPages";
import "./App.css";
import Purchase from "./components/Purchase/Purchase";

class App extends Component {
  state = {
    showModal: true
  };
  componentDidMount() {
    this.props.i18n.changeLanguage("tr-TR");
  }
  render() {
    return (
      <>
        <Switch>
          <Route
            path="/login"
            render={props => <Login {...props} mode="login" />}
          />
          <Route
            path="/signup"
            render={props => <Login {...props} mode="signup" />}
          />
          <Route
            path="/forget-password"
            render={props => <Login {...props} mode="forget" />}
          />

          <Layout location={this.props.location} history={this.props.history}>
            <Switch>
              <PrivateRoute exact path="/policy" component={Policy} />
              <PrivateRoute exact path="/test" component={Test} />
              <PrivateRoute exact path="/purchase" component={Purchase} />
              <PrivateRoute path="/report/:user_id" component={Report} />              
              <PrivateRoute path="/schoolreport/:corporation_id/:type/:division_id" component={SchoolReport} />
              <PrivateRoute exact path="/main" component={Main} />
              <Route
                exact
                path="/reset_password/:email/:validation_code"
                component={ResetPassword}
              />
              <Route path="/" component={InfoPages} />
              <Route component={() => <p>Not Found</p>} />
            </Switch>
          </Layout>
        </Switch>
        <div
          style={{
            position: "fixed",
            bottom: 0,
            width: "100%",
            zIndex: 999
          }}
        >
          <Transition.Group duration={1000} animation="fade" as={List}>
            {this.props.notifications.map(
              notification =>
                !notification.dismiss && (
                  <List.Item key={notification.id}>
                    <Message
                      test="123"
                      notification={notification}
                      hideNotification={this.props.hideNotification}
                    />
                  </List.Item>
                )
            )}
          </Transition.Group>
        </div>
        {/* <Modal
          centered={false}
          open={this.state.showModal}
          size="small"
          onClose={() => this.setState({ showModal: false })}
        >
          <Modal.Content>
            <Modal.Description>
              <Image
                style={{width:"100%"}}               
                size="medium"
                src="/assets/images/lansman-popup.jpg"
              />
            </Modal.Description>
          </Modal.Content>
        </Modal> */}
        )
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    notifications: state.notifications
  };
};
const mapDispatchToProps = dispatch => {
  return {
    hideNotification: id => dispatch(hideNotification(id))
  };
};
export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withI18n()(App))
);
