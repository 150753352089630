import React from 'react';
import './style.css';
import { Image } from 'semantic-ui-react';


// export default ({letter,color}) => (
//   <span className={"TypeCodeLetterContainer " +"TypeCodeLetter"+color}>
//     <span className={"TypeCodeLetter "}>{letter}</span>
//   </span>
// );

export default ({letter}) => (<Image src={`/assets/images/${letter.toLowerCase()}.png`}  className="TypeCodeLetter"/>);