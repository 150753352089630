import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Redirect} from 'react-router-dom'
import { Segment, Grid, Header, Button, Checkbox} from 'semantic-ui-react';
import {withNamespaces} from 'react-i18next';
import {policyAgreed, signOut} from '../../store/actions/';

class Policy extends Component {
  state = {
    confirm: false,
    warning: false,
  }

  buttonHandler = (isAgreed) => {
    let {confirm} = this.state;
    if(confirm && isAgreed) {this.props.policyAgreed(); this.props.history.push('/test')}
    else if (!confirm && isAgreed) {this.setState({warning: true})}
    else this.props.signOut();
  }

  render(){
    const {t, isAgreed} = this.props;
    let { warning} = this.state;
    if(isAgreed) return <Redirect to = '/test'/>
    return(
      <Grid style = {{margin: 'auto'}} verticalAlign = 'middle' >
        <Segment className = 'p-5 dark-text' style = {{maxWidth: 1024}}>
          <Header size = 'large'>
            {t('inventory_module')}
          </Header>
          <Header className = 'mt-1 dark-text' size = 'small' style = {{fontWeight: 300}}>
            {t('policy.meta')}
          </Header>
          <Header className = 'mt-1 dark-text' size = 'medium'>
            {t('policy.title')}
          </Header>
            {t('policy.description', {returnObjects: true}).map((txt, i) => <p key={i}>{txt}</p>)}
          <Grid>
            <Grid.Row columns = {2} verticalAlign = 'middle'>
              <Grid.Column width = {10}>
                <Checkbox label = {<label style = {{color: warning && 'red'}}>{t('policy.confirm')}</label>}  onChange = {(e, data) => this.setState({confirm: data.checked})}/>
              </Grid.Column>
              <Grid.Column textAlign = 'right' width = {6}>
                <Button color = 'teal' onClick = {() => this.buttonHandler(true)}>{t('accept')}</Button>
                <Button color = 'pink' onClick = {() => this.buttonHandler(false)}>{t('decline')}</Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      </Grid>
    )
  }
}

const mapStateToProps = state => {
  return {
    isAgreed: state.auth.isPolicyAgreed,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    policyAgreed: () => dispatch(policyAgreed()),
    signOut: () => dispatch(signOut())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(Policy));
