import React, {lazy} from 'react';
import { Grid } from 'semantic-ui-react';
import { Route } from 'react-router-dom';
import RightColumn from '../../components/InfoPages/RightColumn';
import WaitingComponent from '../../components/WaitingComponent/WaitingComponent';

const InfoLazy = lazy(()=>import('./Info'));
const WhoCanUseLazy = lazy(()=>import('./WhoCanUse/WhoCanUse'));
const FAQLazy = lazy(()=>import('./FAQ/FAQ'));
const HowMuchLazy = lazy(()=>import('./HowMuch/HowMuch'));
const SearchProgramLazy = lazy(()=>import('./SearchProgram/SearchProgram'));
const MembershipLazy = lazy(()=>import('./Membership/Membership'));




export default () => (
  <div className="parent pb-4 HowMuchContainer">
    <Grid container className="pt-4" stackable>
      <Grid.Column width={12} >
        <Route exact path="/info" component={WaitingComponent(InfoLazy)} />
        <Route exact path="/who-can-use" component={WaitingComponent(WhoCanUseLazy)} />
        <Route exact path="/faq" component={WaitingComponent(FAQLazy)} />
        <Route exact path="/search-program" component={WaitingComponent(SearchProgramLazy)} />
        <Route exact path="/membership" component={WaitingComponent(MembershipLazy)} />
        
        {/* <Route exact path="/how-much" component={WaitingComponent(HowMuchLazy)} /> */}
      </Grid.Column>
      <Grid.Column width={4}>
        <RightColumn />
      </Grid.Column>
    </Grid>
  </div>
);