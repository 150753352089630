import React from 'react';
import { Segment, Header} from 'semantic-ui-react';

const style = {
  backgroundImage:"url('/assets/images/sidebar-bg.png')",
  backgroundPosition: "right bottom",
  backgroundRepeat: "no-repeat",
  backgroundSize:"60%"
}


export default ({corporation, print, loading, showPrintButtons}) => (
    <Segment padded style={style} >
      {showPrintButtons&&
      <><Header size="tiny" className="PrintButton" floated="right" icon="file pdf outline" content="Pdf İndir" onClick={loading?()=>{}:print} style={{cursor:loading?"progress":"pointer"}}/>
      <Header size="tiny" className="PrintButton" floated="right" icon="print" content="Yazdır" onClick={()=>loading? {} : print({autoPrint:true})} style={{cursor:loading?"progress":"pointer"}}/></>}
      <Header size="large" content={`${corporation.name}`} style={{
        textTransform:"capitalize"
      }}/>
      <table>
        <tbody>
        {/* {corporation.divisions &&  */
        <tr>
          <td><b>Sınıf</b></td>
          <td>:</td>
          <td>{corporation.divisions_name}</td>
        </tr>}
        {/* {corporation.user_count&& */
        <tr>
          <td><b>Toplam Katılımcı Sayısı</b></td>
          <td>:</td>
          <td>{corporation.user_count}</td>
        </tr>}
        {/* {
          user.birth_date && <tr>
            <td><b>Yaş</b></td>
            <td>:</td>
            <td>{new Date().getFullYear()- (+user.birth_date.substring(0,4)) }</td>
          </tr>
        } */}
        </tbody>
      </table>
    </Segment>
);