import React, { Component } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import {
  Step,
  Icon,
  Segment,
  Button,
  Grid,
  Progress,
  Header,
  Table,
  Select,
  Dropdown,
  Radio,
  Responsive
} from "semantic-ui-react";
import memoizeOne from "memoize-one";
import Slider from "react-slick";
import { submitAnswers, setUserDetails, submitTest } from "../../store/actions";
import { Redirect } from "react-router-dom";
import cities from "../../assets/countries.json";

import { editUser } from "../../store/actions/auth";
import moment from "moment";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import _ from "lodash";
import "./style.css";


const getCities = () => cities.map(city => ({
          key: city.cityName,
          value: city.cityName,
          text: city.cityName
        }));

class Test extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isConfirmed: false,
      eventQuestions: [],
      qualificationQuestions: [],
      active: 0,
      answers: [],
      toSubmit: [],
      activeId: 0,
      completed: (props.test && props.test.is_completed) || false,
      steps: [
        { text: "test.personal", value: "personal" },
        { text: "test.event", value: "event" },
        { text: "test.qualification", value: "qualification" }
      ],
      onTransition: false,
      gender: props.gender || "",
      city: props.city || "",
      birthDate: props.birthDate || "",
      isStudent: "true",
      grade: props.grade || "",
      personalQuestionAnswers: [],
      isSubmitting: false
    };
  }
  getCities = memoizeOne(getCities);
  componentDidMount() {
    if (!this.props.test) return this.props.history.push("/main");
    let { questions } = this.props.test;
    let {
      gender,
      city,
      birthDate,
      steps,
      isStudent,
      grade
    } = this.state;
    let temp = [];
    let active = 0;

    questions.forEach(question => {
      if (!_.isEmpty(question.selected_choice_ids)) {
        temp[question.q_order] = {
          questionId: question.id,
          choiceId: question.selected_choice_ids
        };
      }
    });
    active =
      temp.filter(answer => answer !== null).length === questions.length
        ? steps.length
        : 0;
    this.setState({
      active,
      answers: temp,
      personalQuestionAnswers: [
        !!gender,
        !!city,
        !!birthDate,
        !!isStudent && (isStudent === "false" || !!grade)
      ],
      eventQuestions: questions.filter(
        question => question.q_type === "event"
      ),
      qualificationQuestions: questions.filter(
        question => question.q_type === "qualification"
      )
    }, () => console.log(this.state.personalQuestionAnswers));
  }

  handleSelect = (choiceId, question) => {
    const { activeId, eventQuestions, active } = this.state;
    if (
      activeId !==
      eventQuestions.length + this.getPersonalQuestions().length - 1
    )
      this.slider.slickNext();
    else this.setState({ active: active + 1, activeId: activeId + 1 });
    this.setState({ onTransition: true });
    this.updateAnswers(choiceId, question);
    this.submitAnswers(choiceId, question);
  };

  submitAnswers = (choiceId, question) => {
    const toSubmit = [
      ...this.state.toSubmit,
      {
        question_id: question.id,
        selected_choice_ids: [choiceId],
        q_type: question.q_type
      }
    ];
    this.setState({ toSubmit }, () =>
      this.props
        .submitAnswers(
          this.state.toSubmit.map(({ question_id, selected_choice_ids }) => ({
            question_id,
            selected_choice_ids
          }))
        )
        .then(res => {
          const submitted = this.state.toSubmit.reduce((acc, question) => {
            acc[question.q_type + "Questions"]
              ? acc[question.q_type + "Questions"].push(question)
              : (acc[question.q_type + "Questions"] = [question]);
            return acc;
          }, {});
          const newQuestions = {};
          for (let type in submitted) {
            newQuestions[type] = this.state[type].map(question => {
              const newQuestion = submitted[type].find(
                submittedQuestion =>
                  question.id === submittedQuestion.question_id
              );
              if (newQuestion) {
                return {
                  ...question,
                  selected_choice_ids: newQuestion.selected_choice_ids
                };
              }
              return question;
            });
          }
          if (res) {
            const updatedToSubmit = this.state.toSubmit.filter(answer =>
              toSubmit.every(
                submittedAnswer =>
                  submittedAnswer.question_id !== answer.question_id
              )
            );
            const removedFromSubmit = this.state.toSubmit.filter(answer =>
              toSubmit.some(
                submittedAnswer =>
                  submittedAnswer.question_id === answer.question_id
              )
            );
            this.setState({ toSubmit: updatedToSubmit });
            console.log(
              "removedFromToSubmit",
              removedFromSubmit.map(answer => answer.question_id)
            );
          }
        })
    );
  };

  changeActive = (prevId, nextId) => {
    let { questions } = this.props.test;
    let { steps } = this.state;
    let active = 0;
    if (
      prevId === questions.length + this.getPersonalQuestions().length - 1 &&
      nextId >= prevId
    )
      active = steps.length;
    else
      active = _.findIndex(
        steps,
        step =>
          step.value ===
          this.getPersonalQuestions().concat(questions)[nextId].q_type
      );
    this.setState({ active });
  };

  confirmTest = () => {
    const { submitTest } = this.props;
    this.setState({ isSubmitting: true });
    submitTest().then(() =>
      this.setState({ completed: true, isSubmitting: false })
    );
  };

  handleChange = (choiceId, question) => {
    this.updateAnswers(choiceId, question);
    this.submitAnswers(choiceId, question);
  };

  updateAnswers = (choiceId, question) => {
    this.setState(prevState => {
      let temp = prevState.answers.map(answer => answer);
      temp[question.q_order] = {
        choiceId: [choiceId],
        questionId: question.id
      };
      return { answers: temp };
    });
  };

  getChoices = question => {
    const { answers } = this.state;
    return question.choices.map(choice => {
      if (
        answers[question.q_order] &&
        answers[question.q_order].choiceId.includes(choice.id)
      ) {
        const options = question.choices.map(choice => {
          return { key: choice.id, value: choice.id, text: choice.c_text };
        });
        return (
          <Table.Cell key={choice.id} style={{ background: "#f9fafb" }}>
            <Select
              style={{ minWidth: "12em", fontSize: "0.8em" }}
              value={answers[question.q_order].choiceId[0]}
              options={options}
              onChange={(e, data) => this.handleChange(data.value, question)}
            />
          </Table.Cell>
        );
      }
      return <React.Fragment key={choice.id} />;
    });
  };

  getSteps = () => {
    const {
      steps,
      active,
      completed,
      personalQuestionAnswers,
      eventQuestions,
      qualificationQuestions
    } = this.state;
    const { t } = this.props;
    let personalCompleted = personalQuestionAnswers.every(answer => answer)
    let eventsCompleted = !eventQuestions.find(
      question => !question.selected_choice_ids.length
    );
    let qualificationCompleted = !qualificationQuestions.find(
      question => !question.selected_choice_ids.length
    );
    return (
      <Step.Group
        style={{ margin: "0px" }}
        ordered
        size="small"
        vertical
        attached="top"
      >
        <Step
          className="test-step"
          key={steps[0].value}
          completed={personalCompleted}
          active={active === 0}
          onClick={() => {
            this.setState({ active: 0, activeId: 0 });
            if (this.slider) this.slider.slickGoTo(0);
          }}
        >
          <Step.Content>
            <Step.Title>{t(steps[0].text)}</Step.Title>
          </Step.Content>
        </Step>
        <Step
          className="test-step"
          key={steps[1].value}
          completed={eventsCompleted || active > 1}
          active={active === 1}
          onClick={() => {
            if (personalCompleted) {
              this.setState({
                active: 1,
                activeId: this.getPersonalQuestions().length
              });
              if (this.slider)
                this.slider.slickGoTo(this.getPersonalQuestions().length);
            }
          }}
        >
          <Step.Content>
            <Step.Title>{t(steps[1].text)}</Step.Title>
          </Step.Content>
        </Step>
        <Step
          className="test-step"
          key={steps[2].value}
          completed={qualificationCompleted || active > 2}
          active={active === 2}
          onClick={() => eventsCompleted && this.setState({ active: 2 })}
        >
          <Step.Content>
            <Step.Title>{t(steps[2].text)}</Step.Title>
          </Step.Content>
        </Step>
        <Step
          className="test-step"
          completed={completed}
          active={active === steps.length}
          onClick={() =>
            qualificationCompleted && this.setState({ active: steps.length })
          }
        >
          <Step.Content>
            <Step.Title>{t("test.summary")}</Step.Title>
          </Step.Content>
        </Step>
      </Step.Group>
    );
  };

  getSummary = () => {
    const { steps, isSubmitting } = this.state;
    const { t, test } = this.props;
    let questions = test.questions;
    return (
      <div className="mb-1">
        <Header style={{ marginBottom: "0px" }} as="h2">
          {" "}
          {t("test.summary")}
        </Header>
        <Header className="my-1" as="h5">
          {" "}
          {t("test.summary_desc")}
        </Header>
        <div style={{ height: "50vh", overflow: "auto" }}>
          <Table celled unstackable>
            {[steps[1], steps[2]].map(step => (
              <React.Fragment key={step.value}>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell
                      colSpan="3"
                      style={{ borderTop: "1px solid rgba(34,36,38,.1)" }}
                    >
                      {t(step.text)}
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {questions.map(
                    question =>
                      question.q_type === step.value && (
                        <Table.Row key={question.id}>
                          <Table.Cell
                            style={{
                              background: "#f9fafb",
                              fontWeight: "bold",
                              textAlign: "center"
                            }}
                          >
                            {question.q_order + 1}
                          </Table.Cell>
                          <Table.Cell style={{ fontSize: "1em" }}>
                            {question.q_text}
                          </Table.Cell>
                          {this.getChoices(question)}
                        </Table.Row>
                      )
                  )}
                </Table.Body>
              </React.Fragment>
            ))}
          </Table>
        </div>
        <Button
          loading={isSubmitting}
          content={t("confirm")}
          onClick={this.confirmTest}
          color="teal"
          floated="right"
          className="my-1"
        />
      </div>
    );
  };

  getQuestions = () => {
    const {
      activeId,
      answers,
      onTransition,
      personalQuestionAnswers,
      active,
      eventQuestions,
      qualificationQuestions,
      isConfirmed
    } = this.state;
    const { test } = this.props;
    let personalQuestionSize = this.getPersonalQuestions().length;
    let questions = test.questions;
    let showConfirmation =
      !answers.length && !isConfirmed && activeId === personalQuestionSize;
    let isForwardActive = !(
      onTransition ||
      activeId === questions.length + personalQuestionSize - 1 ||
      (activeId >= personalQuestionSize &&
        !answers[activeId - personalQuestionSize + 1] &&
        !answers[activeId - personalQuestionSize]) ||
      (activeId < personalQuestionSize && !personalQuestionAnswers[activeId])
    );
    let isPrevActive = activeId !== 0;
    const forwardButtonOnClick = () => {
      if (isForwardActive) {
        const slickIndex = personalQuestionAnswers.includes(false)
          ? _.findIndex(personalQuestionAnswers, answer => !answer)
          : answers.length + personalQuestionSize;
        this.slider.slickGoTo(slickIndex);
        this.setState({
          activeId:
            answers.length < questions.length
              ? answers.length
              : questions.length -
                qualificationQuestions.length +
                personalQuestionSize -
                1
        });
      }
    };
    let forwardButton = (
      <Icon
        style={{ color: isForwardActive ? "#7B7B7B" : "#EFEFEF" }}
        name="angle double right"
        size="big"
        onClick={forwardButtonOnClick}
      />
    );
    let prevButton = (
      <Icon
        style={{ color: isPrevActive ? "#7B7B7B" : "#EFEFEF" }}
        name="angle double left"
        size="big"
        onClick={() => {
          isPrevActive && this.slider.slickGoTo(0);
          this.setState({ activeId: 0 });
        }}
      />
    );
    let settings = {
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      swipe: false,
      nextArrow: (
        <Arrows
          hide={showConfirmation}
          disabled={!isForwardActive}
          right
          wind={forwardButton}
          name="angle right"
        />
      ),
      prevArrow: (
        <Arrows
          hide={showConfirmation}
          disabled={!isPrevActive}
          wind={prevButton}
          name="angle left"
        />
      )
    };
    return (
      <div className="slider-container" style={{ position: "relative" }}>
        {showConfirmation && this.getConfirmation()}
        {active === 0 || active === 1 ? (
          <Slider
            key={active === 0 || active === 1}
            {...settings}
            ref={ref => (this.slider = ref)}
            initialSlide={activeId}
            beforeChange={(prevId, activeId) => {
              this.setState({ activeId, onTransition: false });
              this.changeActive(prevId, activeId);
            }}
          >
            {this.getPersonalQuestions().map((question, index) => {
              return (
                <div
                  key={"personal" + index}
                  style={{}}
                  className="carousel-question-image"
                >
                  <img
                    style={{ margin: "auto", width: "50%" }}
                    src={question.image_file_name}
                    alt={"personal" + index}
                  />
                  <div
                    className=" carousel-question-text"
                    style={{
                      textAlign: "center",
                      fontWeight: 800,
                      fontSize: "1.5em",
                      color: "#424242",
                      width: "80%",
                      margin: "auto",
                      padding: "1em",
                      marginBottom: "3em",
                      lineHeight: "1.5em"
                    }}
                  >
                    {question.q_text}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center"
                    }}
                  >
                    {question.choices}
                  </div>
                </div>
              );
            })}
            {eventQuestions.map((question, activeId) => {
              return (
                <div key={activeId} className="carousel-question-image">
                  <img
                    style={{ margin: "auto", width: "50%" }}
                    src={question.image_file_name}
                    alt={"question" + activeId}
                  />
                  <div
                    className=" carousel-question-text"
                    style={{
                      textAlign: "center",
                      fontWeight: 800,
                      fontSize: "1.5em",
                      color: "#424242",
                      width: "80%",
                      margin: "auto",
                      padding: "1em",
                      marginBottom: "1em",
                      lineHeight: "1.5em"
                    }}
                  >
                    {question.q_text}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      margin: "auto",
                      width: "70%"
                    }}
                  >
                    {question.choices.map(choice => (
                      <Button
                        className="choice-button"
                        style={
                          answers[activeId] &&
                          answers[activeId].choiceId.includes(choice.id)
                            ? { background: "#00BC9D", color: "white" }
                            : {}
                        }
                        key={choice.id}
                        content={choice.c_text}
                        onClick={() => this.handleSelect(choice.id, question)}
                      />
                    ))}
                  </div>
                </div>
              );
            })}
          </Slider>
        ) : (
          this.getQualificationQuestions()
        )}
      </div>
    );
  };

  getQualificationQuestions = () => {
    const { qualificationQuestions, answers, steps } = this.state;
    const { t, test } = this.props;
    return (
      <div className="mb-4 mt-1">
        <p>{t("test.qualificationDesc")}</p>
        <div className="ScroollableTableContainer">
          <Table
            celled
            unstackable
            definition
            columns="3"
            selectable
            className="ScrollableTable"
          >
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell
                  className="px-2"
                  textAlign="center"
                  singleLine
                />
                <Table.HeaderCell
                  className="px-2"
                  textAlign="center"
                  singleLine
                >
                  <Responsive minWidth={Responsive.onlyTablet.minWidth}>
                    {qualificationQuestions[1].choices[1].c_text}
                  </Responsive>
                  <Responsive maxWidth={Responsive.onlyMobile.maxWidth}>
                    <Icon name="check" />
                  </Responsive>
                </Table.HeaderCell>
                <Table.HeaderCell
                  className="px-2"
                  textAlign="center"
                  singleLine
                >
                  <Responsive minWidth={Responsive.onlyTablet.minWidth}>
                    {qualificationQuestions[0].choices[0].c_text}
                  </Responsive>
                  <Responsive maxWidth={Responsive.onlyMobile.maxWidth}>
                    <Icon name="close" />
                  </Responsive>
                </Table.HeaderCell>

              </Table.Row>
            </Table.Header>
            <Table.Body>
              {qualificationQuestions.map((question, index) => (
                <Table.Row key={index}>
                  <Table.Cell>{question.q_text}</Table.Cell>
                  <Table.Cell
                    textAlign="center"
                    onClick={
                      answers[question.q_order] &&
                      answers[question.q_order].choiceId[0] ===
                        question.choices[1].id
                        ? () => {}
                        : () =>
                            this.handleChange(question.choices[1].id, question)
                    }
                  >
                    {answers[question.q_order] &&
                    answers[question.q_order].choiceId[0] ===
                      question.choices[1].id ? (
                      <Icon name="check" />
                    ) : (
                      <Radio
                        className="radio-success"
                        checked={
                          answers[question.q_order] &&
                          answers[question.q_order].choiceId[0] ===
                            question.choices[1].id
                        }
                        /* onChange={() => this.handleChange(question.choices[1].id, question)} */
                      />
                    )}
                  </Table.Cell>
                  <Table.Cell
                    textAlign="center"
                    onClick={
                      answers[question.q_order] &&
                      answers[question.q_order].choiceId[0] ===
                        question.choices[0].id
                        ? () => {}
                        : () =>
                            this.handleChange(question.choices[0].id, question)
                    }
                  >
                    {answers[question.q_order] &&
                    answers[question.q_order].choiceId[0] ===
                      question.choices[0].id ? (
                      <Icon name="close" />
                    ) : (
                      <Radio
                        className="radio-danger"
                        checked={
                          answers[question.q_order] &&
                          answers[question.q_order].choiceId[0] ===
                            question.choices[0].id
                        }
                        /* onChange={() => this.handleChange(question.choices[0].id, question)} */
                      />
                    )}
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </div>
        {answers.filter(answer => answer !== null).length ===
          test.questions.length && (
          <Button
            icon
            labelPosition="right"
            color="teal"
            floated="right"
            onClick={() => this.setState({ active: steps.length })}
            className="my-1"
          >
            {t("next")}
            <Icon name="right arrow" />
          </Button>
        )}
      </div>
    );
  };

  getConfirmation = () => {
    const { t } = this.props;
    return (
      <div
        className="TestConfirmation px-2"
        style={{
          height: "100%",
          zIndex: 2,
          backgroundColor: "white",
          position: "absolute"
        }}
      >
        <Header as="h1">
          <b>{t("test.event")}</b>
        </Header>
        <div>{t("test.confirmation.top")}</div>
        <Header as="h4">
          <b>{t("test.confirmation.middle")}</b>
        </Header>
        <div style={{ marginBottom: "1em" }}>
          {t("test.confirmation.bottom")}
        </div>
        <div style={{ marginBottom: "1em" }}>{t("test.confirmation.last")}</div>
        <Button
          fluid
          content={t("start")}
          color="teal"
          onClick={() => this.setState({ isConfirmed: true })}
        />
      </div>
    );
  };

  getPersonalQuestions = () => {
    const { t, setUserDetails } = this.props;
    const {  gender, birthDate, city, isStudent, grade } = this.state;

    const handleCitySelection = (event, data) => {
      const personalQuestionAnswers = [...this.state.personalQuestionAnswers];
      personalQuestionAnswers[1] = true;
      this.setState({ city: data.value, personalQuestionAnswers });
      this.slider.slickNext();
      setUserDetails({ city: data.value });
    };
    const handleBirthDateSelection = (event, data) => {
      const personalQuestionAnswers = [...this.state.personalQuestionAnswers];
      personalQuestionAnswers[2] = true;
      this.setState({ birthDate: data.value, personalQuestionAnswers });
      this.slider.slickNext();
      this.props.editUser({
        birth_date: moment(`02-01-${data.value}`, "DD-MM-YYYY")._d
      });
    };
    const handleGenderSelection = value => {
      this.slider.slickNext();
      const personalQuestionAnswers = [...this.state.personalQuestionAnswers];
      personalQuestionAnswers[0] = true;
      this.setState({ gender: value, personalQuestionAnswers });
      this.props.editUser({ gender: value });
    };
    const handleEducationSelection = value => {
      const personalQuestionAnswers = [...this.state.personalQuestionAnswers];
      personalQuestionAnswers[3] = value === "false" || !!this.state.grade;
      this.setState({ isStudent: value, personalQuestionAnswers });
      value === "false" && this.slider.slickNext();
      setUserDetails({ isStudent: value });
    };
    const handleGradeSelection = (event, data) => {
      const personalQuestionAnswers = [...this.state.personalQuestionAnswers];
      personalQuestionAnswers[3] = true;
      this.setState({ grade: data.value, personalQuestionAnswers });
      this.slider.slickNext();
      setUserDetails({ grade: data.value });
    };
    let ButtonStyle = (key, value) =>
      Object.assign(
        {},
        key === value ? { background: "#00BC9D", color: "white" } : {},
        { marginTop: "1em", marginBottom: "1em", width: "10em" }
      );

    const questions = [
      {
        image_file_name:
          "https://s3.amazonaws.com/brainquire/inventory_images/app/gender-8.png",
        q_order: 0,
        q_text: "Cinsiyetiniz",
        q_type: "personal",
        choices: [
          <Button
            style={ButtonStyle(gender, "male")}
            key="kadin"
            content={t("test.male")}
            onClick={() => handleGenderSelection("male")}
          />,
          <Button
            style={ButtonStyle(gender, "female")}
            key="erkek"
            content={t("test.female")}
            onClick={() => handleGenderSelection("female")}
          />
        ]
      },
      {
        image_file_name:
          "https://s3.amazonaws.com/brainquire/inventory_images/app/country-8.png",
        q_order: 1,
        q_text: "Yaşadığınız Şehir",
        q_type: "personal",
        choices: (

              <Dropdown
                style={{ width: "15em" }}
                className="CityDropDown"
                placeholder={t("test.select_city")}
                fluid
                search
                selection
                value={city}
                options={this.getCities()}
                onChange={handleCitySelection}
              />)   
      },
      {
        image_file_name:
          "https://s3.amazonaws.com/brainquire/inventory_images/app/birtdate-8.png",
        q_order: 2,
        q_text: "Doğum Yılınız",
        q_type: "personal",
        choices: (
          <Dropdown
            style={{ width: "10em" }}
            placeholder={t("test.year")}
            fluid
            search
            selection
            value={birthDate}
            options={years}
            onChange={handleBirthDateSelection}
          />
        )
      },
      {
        image_file_name:
          "https://s3.amazonaws.com/brainquire/inventory_images/app/student-8.png",
        q_order: 3,
        q_text: "Kaçıncı Sınıftasınız?",
        q_type: "personal",
        choices: (
          <Dropdown
                className="GradeDropDown"
                style={{ marginBottom: "2em", width: "15em" }}
                placeholder={t("test.select_grade")}
                fluid
                search
                selection
                value={grade}
                options={[
                  { key: 0, text: "Öğrenci Değilim", value: "-1" },
                  { key: 1, text: "9", value: "9" },
                  { key: 2, text: "10", value: "10" },
                  { key: 3, text: "11", value: "11" },
                  { key: 4, text: "12", value: "12" },
                  { key: 5, text: "Diğer", value: "0" }
                ]}
                onChange={handleGradeSelection}
              />
        )
      }
    ];

    return questions;
  };

  render() {
    const { active, steps, answers, completed } = this.state;
    const { test } = this.props;
    if (!test) return <Redirect to="/main" />;
    if (completed) return <Redirect to={"/report/" + this.props.user_id} />;
    let questions = test.questions;
    let content = {};
    let isSummary = active === steps.length;
    if (isSummary) {
      content = this.getSummary();
    } else {
      content = this.getQuestions();
    }
    return (
      <div className="justify-center" style={{ width: "100%" }}>
        <Grid padded style={{ maxWidth: "800px" }} stackable columns={2}>
          <Grid.Column className="justify-center" computer="5" tablet={16}>
            <Responsive
              as={React.Fragment}
              maxWidth={Responsive.onlyTablet.maxWidth}
            >
              {this.getSteps()}
            </Responsive>
            <Responsive minWidth={Responsive.onlyTablet.maxWidth}>
              <div>{this.getSteps()}</div>
            </Responsive>
          </Grid.Column>
          <Grid.Column
            className="justify-center p-1 pl-0"
            computer="11"
            tablet={16}
          >
            <Segment className="p-0 right-segment">
              <Progress
                className="mb-1"
                percent={
                  active === steps.length
                    ? 100
                    : (100 * answers.filter(answer => answer !== null).length) /
                      questions.length
                }
                color="teal"
                size="tiny"
              />
              <div className="pr-2 pl-2 py-1">{content}</div>
            </Segment>
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

const Arrows = props => {
  const {
    className,
    onClick,
    name,
    style,
    wind,
    right,
    disabled,
    hide
  } = props;

  return (
    <div
      className={`TestSliderButtons ${className}`}
      style={{
        display: hide ? "none" : "flex",
        flexDirection: right ? "row-reverse" : "row"
      }}
    >
      <div>{wind}</div>
      <div className="mx-4">
        <Icon
          style={{ ...style, color: disabled ? "#EFEFEF" : "#7B7B7B" }}
          name={name}
          size="big"
          onClick={() => !disabled && onClick && onClick()}
        />
      </div>
    </div>
  );
};

const years = [...new Array(30)].map((_, i) => ({
  key: 1980 + i,
  value: 1980 + i + "",
  text: 1980 + i
}));

const mapStateToProps = state => {
  return {
    test: state.tests[0],
    gender: state.auth.user.gender,
    inventory:state.auth.user.corporations && state.auth.user.corporations[0],
    user_id: state.auth.user.id,
    grade: state.auth.details.grade,
    birthDate: state.auth.user.birth_date
      ? moment(state.auth.user.birth_date).format("YYYY")
      : "",
    city: state.auth.details.city,
    isStudent: state.auth.details.isStudent,
    isSubmitting: state.loading["SUBMIT_TEST"]
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setUserDetails: params => dispatch(setUserDetails([params])),
    editUser: params => dispatch(editUser(params)),
    submitAnswers: answers => dispatch(submitAnswers(answers)),
    submitTest: () => dispatch(submitTest())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces()(Test));
