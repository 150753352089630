import React from "react";
import { Segment, Image, Header, Grid, Placeholder } from "semantic-ui-react";
import "./style.css";
export default ({
  name,
  color,
  interests,
  abilities,
  features,
  icon,
  description,
  placeholder,
  subTypes
}) => {
  const lists = [
    {
      title: "İlgileri",
      data: interests
    },
    {
      title: "Becerileri",
      data: abilities
    },
    {
      title: "Öne Çıkan Özellikleri",
      data: features
    }
  ];
  return (
    <Segment className="p-4 ">
      <div
        className="TypeDetailsCardIcon"
        style={{ display: "flex", fontFamily: "Open Sans!important" }}
      >
        <Image
          src={
            placeholder
              ? "/assets/images/type-ph.png"
              : `/assets/images/${icon}-icon.png`
          }
          style={{ flex: "0 0 auto" }}
        />
        <div
          className="ml-1"
          style={{
            display:  "flex",
            flexDirection: "column",
            justifyContent: subTypes? "space-evenly":"space-between",
            width: "100%"
          }}
        >
          {!subTypes && (
            <Header
              size="medium"
              content="Kişilik Tipiniz:"
              style={{ fontWeight: 400, textDecoration: "underline" }}
              className="my-0"
            />
          )}
          {placeholder ? (
            <Placeholder>
              <Placeholder.Header>
                <Placeholder.Line />
              </Placeholder.Header>
            </Placeholder>
          ) : (
            <Header
              style={{
                fontFamily: "Open Sans",
                fontWeight: 900,
                fontSize: "3em",
                color: "#36495D"
              }}
              size="huge"
              className="my-0"
            >
              <span style={{ color: color }}>{name[0]}</span>
              {name.substring(1)}
            </Header>
          )}
          {placeholder ? (
            <div className="mr-4 mb-2">
              <Placeholder fluid>
                <Placeholder.Header>
                  <Placeholder.Line />
                </Placeholder.Header>
                <Placeholder.Header>
                  <Placeholder.Line />
                </Placeholder.Header>
              </Placeholder>
            </div>
          ) : (
            <p style={{ fontSize: "1.05em" }}>Kişilik tipi <strong>{name}</strong>{" "}{description.toLocaleLowerCase('tr')}</p>
          )}
        </div>
      </div>
      {!subTypes &&
        <Grid columns={3} padded stretched stackable>
          {lists.map((list, i) => (
            <Grid.Column key={i}>
              <div className="TypeDetailListBox">
                {placeholder ? (
                  <Placeholder>
                    <Placeholder.Paragraph>
                      <Placeholder.Line />
                      <Placeholder.Line />
                      <Placeholder.Line />
                      <Placeholder.Line />
                      <Placeholder.Line />
                      <Placeholder.Line />
                      <Placeholder.Line />
                    </Placeholder.Paragraph>
                    <Placeholder.Paragraph>
                      <Placeholder.Line />
                      <Placeholder.Line />
                      <Placeholder.Line />
                      <Placeholder.Line />
                      <Placeholder.Line />
                    </Placeholder.Paragraph>
                  </Placeholder>
                ) : (
                  <>
                    <Header
                      className="TypeDetailsSubHeader"
                      content={list.title}
                      size="large"
                    />
                    <ul>
                      {list.data.map((interest, i) => (
                        <li key={i} style={{ fontSize: "1.05em" }}>
                          {interest}
                        </li>
                      ))}
                    </ul>
                  </>
                )}
              </div>
            </Grid.Column>
          ))}
        </Grid>
      }
    </Segment>
  );
};
