import React, {Component} from 'react'
import { Message } from 'semantic-ui-react';
class CustomMessage extends Component {

    constructor(props){
        super(props);
        this.timeoutId=-1;
        this.state={
            hiding:false
        }
    }
    componentDidMount(){
        this.timeoutId=setTimeout(()=>this.props.hideNotification(this.props.notification.id),this.props.autoHide || 2000);
    }
    hideNotificationManually(){
        clearTimeout(this.timeoutId);
        this.props.hideNotification(this.props.notification.id);
    }
    render() {
        const { notification } = this.props;
        return (
            <div className={"CustomMessage"+this.state.hiding?" Hiding":""}>
                <Message
                    onDismiss={()=>this.props.hideNotification(notification.id)}
                    positive={notification.positive}
                    negative={notification.negative}
                    content={notification.message}
                />
            </div>
        )
    }
}
export default CustomMessage;
