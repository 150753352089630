import {HIDE_NOTIFICATION, SHOW_NOTIFICATION} from './actionTypes';


export const showNotification= notification => {
    return {
        type:SHOW_NOTIFICATION,
        notification:{...notification,dismiss:false}
    }
}

export const hideNotification = (notificationIndex) => {
    return {
        type:HIDE_NOTIFICATION,
        notificationIndex
    }
}