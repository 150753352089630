import {
  GET_TEST_SUCCESS,
  SUBMIT_TEST_SUCCESS,
  SIGN_OUT,
  RESET_TEST_SUCCESS
} from "../actions/actionTypes";

const initialState = [];

const testsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_TEST_SUCCESS:
      const test = {
        ...action.test,
        questions:
          action.test.questions &&
          action.test.questions
            .map((question, index) => ({
              ...question,
              q_order: action.test.orders.indexOf(question.q_order)
            }))
            .sort((q1, q2) => q1.q_order - q2.q_order)
      };
      return [...state, test];
    case SUBMIT_TEST_SUCCESS:
      return [
        {
          ...state[0],
          is_completed: true
        }
      ];
    case SIGN_OUT:
      return initialState;
    case RESET_TEST_SUCCESS:
      return initialState;
    default:
      return state;
  }
};

export default testsReducer;
