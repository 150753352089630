import React from 'react';
import { Grid, Segment, Header, Image } from 'semantic-ui-react';
import { withNamespaces } from 'react-i18next';
import TypeCodeLetter from "../TypeCode/TypeCodeLetter"
import { typeColor } from '../../../../helpers/typeColor';
import './style.css';

export default withNamespaces()(({ data, t, letter }) => (
  <Grid.Column style={{width:"380px", height:"200px"}}>
    <Segment textAlign="center" className="p-2 TypeDescriptionCard" padded  style={{display:"flex", justifyContent:"center"}}>
    <div>
{letter && <TypeCodeLetter letter={letter} key={letter} />}      
      <Header textAlign="center"  className="ReportHeader" style={{fontFamily:"Open Sans",fontWeight:900}} size="large">
        <span style={{color:typeColor(data)}}>{t("types."+data+".name")[0]}</span>
        {t("types."+data+".name").substring(1)}</Header>
      </div>
      
    </Segment>
  </Grid.Column>
));