import React from 'react';
import { Segment, Header, Image } from 'semantic-ui-react';
import TypeCodeLetter from './TypeCodeLetter';

export default ({ data, placeholder }) => (
  <Segment textAlign="center" style={{display:"flex",flexDirection:"column"}}>
    <Header className="ReportHeader" size="large" content="Kişilik Tipi Kodunuz" textAlign="left" />
    <div className="p-3 TypeCodeLetterContainer" style={{display:"flex", justifyContent:"center"}}>

      { placeholder?
        <Image src='/assets/images/code-ph.png' />:
        data.map(letter => (
          <TypeCodeLetter letter={letter} key={letter} />
        ))
      }
    </div>
  </Segment>
)
