import {showNotification} from './';
import {
    SIGN_IN_SUCCESS,
    SIGN_OUT,
    SIGN_IN_REQUEST,
    SIGN_IN_FAIL,
    POLICY_AGREED,
    SIGN_UP_REQUEST,
    SIGN_UP_SUCCESS,
    SIGN_UP_FAIL,
    FORGET_PASSWORD_REQUEST,
    FORGET_PASSWORD_SUCCESS,
    FORGET_PASSWORD_FAIL,
    RESET_PASSWORD_REQUEST,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAIL,
    EDIT_USER_REQUEST,
    EDIT_USER_SUCCESS,
    EDIT_USER_FAIL,
    GET_USER_DETAILS_REQUEST,
    GET_USER_DETAILS_SUCCESS,
    GET_USER_DETAILS_FAIL,
    SET_USER_DETAILS_REQUEST,
    SET_USER_DETAILS_SUCCESS,
    SET_USER_DETAILS_FAIL,
} from './actionTypes';
import axios from '../../config/axios-instance';
import api from '../../config/api';

const signInSuccess = (token, user) => {
    return {
        type: SIGN_IN_SUCCESS,
        token,
        user
    }
}
const singInRequest = () => {
    return {
        type: SIGN_IN_REQUEST
    }
}
const signInFail = () => {
    return {
        type: SIGN_IN_FAIL
    }
}

export const signIn = (creds) => {
  return dispatch => {
    dispatch(singInRequest());
    return axios.post(api.sign_in, { ...creds,
      platform: "WEB"
    })
    .then(res => {
      dispatch(signInSuccess(res.data.token, res.data.user));
      dispatch(showNotification({message:"Login Successful",positive:true}))
      localStorage.setItem("user", JSON.stringify(res.data.user));
      localStorage.setItem("token", res.data.token);
      return true;
    })
    .catch(error => {
      dispatch(showNotification({message:error.message,negative:true}));
      dispatch(signInFail());
    })
  }
}

export const autoSignIn = () => {
  return dispatch => {
    const url = window.location.href;

    const token = new URLSearchParams(url.split('?')[1]).get("token");
    if(token) {
      axios.get(api.me,{params:{token}})
        .then(result=>{
          if(result.data.user)
          {
            dispatch(signInSuccess(token,result.data.user));
            localStorage.setItem("user", JSON.stringify(result.data.user));
            localStorage.setItem("token", token);
          }
        })
        .catch(error=> {
          dispatch(signOut());
        })
    }
    else {
      const user = JSON.parse(localStorage.getItem("user"));
      const token = localStorage.getItem("token");
      if (user && token) {
          dispatch(signInSuccess(token, user));
      }
    }

  }
}

const signOutSuccess = () => {
  return {
    type: SIGN_OUT
  }
}
export const signOut = () => {
  localStorage.removeItem("user");
  localStorage.removeItem("token");
  return signOutSuccess();
}

export const fbSignIn = (user) => {
  return dispatch => {
    dispatch(singInRequest());
    axios.post(api.fb_sign_in, {
        first_name: user.first_name,
        last_name: user.last_name,
        fb_id: user.id,
        fb_email: user.email,
        picture_url: user.picture.data.url,
        platform: "WEB",
    })
    .then(res => {
        dispatch(signInSuccess(res.data.token, res.data.user));
        dispatch(showNotification({message:"Login Successful",positive:true}))
        localStorage.setItem("user", JSON.stringify(res.data.user));
        localStorage.setItem("token", res.data.token);
        return true;
    })
    .catch(error => {
        dispatch(showNotification({message:error.message,negative:true}));
        dispatch(signInFail());
    })
  }
}
export const policyAgreed = () => {
    return{
      type: POLICY_AGREED
    }
  }

  const signUpRequest = () => {
      return {
          type:SIGN_UP_REQUEST
      }
  }

  const signUpSuccess = (token,user) => {
      return {
          type:SIGN_UP_SUCCESS,
          user,token
      }
  }

  const signUpFail = () => {
      return {
          type:SIGN_UP_FAIL
      }
  }

  export const signUp = ({firstName,lastName,email,password}) => dispatch => {
    dispatch(signUpRequest());
    return axios.post(api.sign_up,{
        first_name:firstName,
        last_name:lastName,
        email,password
    }).then(res=>{
        dispatch(signUpSuccess(res.data.token,res.data.user));
        dispatch(showNotification({message:"sign up successful",positive:true}));
        localStorage.setItem("user", JSON.stringify(res.data.user));
        localStorage.setItem("token", res.data.token);
    }).catch(error=> {
        dispatch(signUpFail());
        dispatch(showNotification({message:error.message,negative:true}));
    })
  }

const forgetPasswordRequest = () => {
  return {
    type: FORGET_PASSWORD_REQUEST
  }
}

const forgetPasswordSuccess = () => {
  return {
    type: FORGET_PASSWORD_SUCCESS,
  }
}

const forgetPasswordFail = () => {
  return {
    type: FORGET_PASSWORD_FAIL
  }
}

export const forgetPassword = (email) => dispatch => {
  dispatch(forgetPasswordRequest());
  return axios.post(api.forget_password,{
    email,
    callback_url: 'https://brainquire-inventory.firebaseapp.com/reset_password/'
   }).then(res => {
    dispatch(forgetPasswordSuccess());
    dispatch(showNotification({message: "Mail Başarıyla Gönderildi.", positive:true}));
  }).catch(error => {
    dispatch(forgetPasswordFail());
    dispatch(showNotification({message: "Mail Gönderme Başarısız.", negative:true}));
  })
}

const resetPasswordRequest = () => {
  return {
    type: RESET_PASSWORD_REQUEST
  }
}

const resetPasswordSuccess = () => {
  return {
    type: RESET_PASSWORD_SUCCESS,
  }
}

const resetPasswordFail = () => {
  return {
    type: RESET_PASSWORD_FAIL
  }
}

export const resetPassword = (creds) => dispatch => {
  dispatch(resetPasswordRequest());
  return axios.post(api.reset_password,{
    email: creds.email,
    validation_code: creds.validationCode,
    new_password: creds.password
  }).then(res => {
    dispatch(resetPasswordSuccess());
    dispatch(showNotification({message: "Şifreniz Başarıyla Değiştirildi.", positive:true}));
  }).catch(error => {
    dispatch(resetPasswordFail());
    dispatch(showNotification({message: `Şifre Değiştirme Başarısız. Hata: ${error.message}`, negative:true}));
  })
}

const editUserRequest = () => {
  return {
    type: EDIT_USER_REQUEST,
  }
}

const editUserSuccess = (res) => {
  return {
    type: EDIT_USER_SUCCESS,
    user: res.data.user
  }
}

const editUserFail = () => {
  return {
    type: EDIT_USER_FAIL
  }
}

export const editUser = (params) => dispatch => {
  dispatch(editUserRequest());
  return axios.post(api.user_edit,params).then(res => {
    localStorage.setItem('user', JSON.stringify(res.data.user))
    dispatch(editUserSuccess(res));
  }).catch(error => {
    dispatch(editUserFail());
  })
}

const getUserDetailsRequest = () => {
  return {
    type: GET_USER_DETAILS_REQUEST,
  }
}

const getUserDetailsSuccess = (res) => {
  return {
    type: GET_USER_DETAILS_SUCCESS,
    details: res.data.details
  }
}

const getUserDetailsFail = () => {
  return {
    type: GET_USER_DETAILS_FAIL
  }
}

export const getUserDetails = () => dispatch => {
  dispatch(getUserDetailsRequest());
  return axios.get(api.get_user_details).then(res => {
    dispatch(getUserDetailsSuccess(res));
  }).catch(error => {
    dispatch(getUserDetailsFail());
  })
}

const setUserDetailsRequest = () => {
  return {
    type: SET_USER_DETAILS_REQUEST,
  }
}

const setUserDetailsSuccess = (details) => {
  return {
    type: SET_USER_DETAILS_SUCCESS,
    details
  }
}

const setUserDetailsFail = () => {
  return {
    type: SET_USER_DETAILS_FAIL
  }
}

export const setUserDetails = (params) => dispatch => {
  dispatch(setUserDetailsRequest());
  return axios.post(api.set_user_details, {details: params}).then(res => {
    dispatch(setUserDetailsSuccess(params));
  }).catch(error => {
    dispatch(setUserDetailsFail());
  })
}
