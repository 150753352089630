import React from 'react';
import { Segment, Header} from 'semantic-ui-react';

const style = {
  backgroundImage:"url('/assets/images/sidebar-bg.png')",
  backgroundPosition: "right bottom",
  backgroundRepeat: "no-repeat",
  backgroundSize:"60%"
}


export default ({user, print, loading, showPrintButtons}) => {

  if(!user){
    return <div style={{textAlign:"center", fontSize:"large"}}>Kullanıcı henüz testi tamamlamadı.</div>
  }
  else{
     return <Segment padded style={style} >
      {showPrintButtons&&
      <><Header size="tiny" className="PrintButton" floated="right" icon="file pdf outline" content="Pdf İndir" onClick={loading?()=>{}:print} style={{cursor:loading?"progress":"pointer"}}/>
      <Header size="tiny" className="PrintButton" floated="right" icon="print" content="Yazdır" onClick={()=>loading? {} : print({autoPrint:true})} style={{cursor:loading?"progress":"pointer"}}/></>}
      <Header size="large" content={`${user.first_name} ${user.last_name}`} style={{
        textTransform:"capitalize"
      }}/>
      <table>
        <tbody>
        {user.corporation_name&& <tr>
          <td><b>Okulu</b></td>
          <td>:</td>
          <td>{user.corporation_name}</td>
        </tr>}
        {user.corp_division_name&&<tr>
          <td><b>Sınıfı</b></td>
          <td>:</td>
          <td>{user.corp_division_name}</td>
        </tr>}
        {
          user.birth_date && <tr>
            <td><b>Yaş</b></td>
            <td>:</td>
            <td>{new Date().getFullYear()- (+user.birth_date.substring(0,4)) }</td>
          </tr>
        }
        {user.gender&&<tr>
          <td><b>Cinsiyet</b></td>
          <td>:</td>
          <td>{user.gender=== "male"?"Erkek" :user.gender ==="female"? "Kadın": user.gender}</td>
        </tr>}
       
        </tbody>
      </table>
    </Segment>
  }

  
};