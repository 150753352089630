import React, {Component} from 'react';
import { withNamespaces } from 'react-i18next';
import { Form, Button, Header } from 'semantic-ui-react';
import {connect} from 'react-redux';
import {forgetPassword} from '../../store/actions/auth';

class ForgotPassword extends Component {
  state = {
    email: this.props.email || ''
  }

  changeHandler = (e) => {
    const email = e.target.value
    this.setState({email});
  }


  render(){
    let {email} = this.state;
    const {t, forgetPassword} = this.props;
    return(
      <>
        <Form size="large">
          <Header as='h4' color='grey'>{t('login.enter_registered_email')}</Header>
          <Form.Input
            className="rounded"
            fluid
            icon="mail"
            iconPosition="left"
            placeholder={t('email')}
            name="email"
            onChange={this.changeHandler}
            value={email}
          />
          <Button
            className="rounded"
            fluid
            secondary
            size="large"
            onClick={() => forgetPassword(email)}
            style={{backgroundColor: "#38BA9B"}}
          >
            {t('send')}
          </Button>
        </Form>
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
  };
};
const mapDispatchToProps = dispatch => {
  return {
    forgetPassword: (email) => dispatch(forgetPassword(email)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(ForgotPassword))
