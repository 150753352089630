export default {
  // USER APIs
  sign_in: "/user/sign_in",
  fb_sign_in: "/user/facebook_signin",
  forget_password: "user/forget_password",
  sign_up: "user/sign_up",
  reset_password: "/user/reset_password",
  user_edit: "/user/edit",
  get_user_details: "user/get_details",
  set_user_details: "user/set_details",
  me: "user/me",

  // TEST APIs
  checkout: "/tests/checkout",
  get_tests: "/tests/get_tests",
  get_test: "tests/get_test",
  submit_answers: "tests/submit_answers",
  get_results: "tests/get_results",
  submit_test: "tests/submit_test",
  submit_jobs: "tests/submit_jobs",
  reset_test: "tests/reset_test",
  get_corporation_results: "tests/corp_stats",
  get_corporation_user_results: "tests/corp_user_results",

  // COUPON APIS
  validate_coupon: "receipt/validate_inventory_coupon",

  //CORPORATION APIs
  get_div_names_and_counts:
    "corporation/get_info_and_div_names_with_user_counts",
  get_divs_and_users: "corporation/get_divs_and_users",
  get_corporation_details: "corporation/get_corporation_details",
};
