let baseURL = 'https://brainquire-server.herokuapp.com';

// let baseURL= "https://aws.brainquire.com";
// let baseURL = "http://localhost:3000";
const infoURL = "https://info.griceviz.com/";
const inventoryURL = "http://meslekilgi.com"; // kendisi
const programLink = "https://yokatlas.yok.gov.tr/netler-tablo.php?b=";
const jobImageLink =
  "https://s3.amazonaws.com/brainquire/inventory_images/jobs";
// if (process.env.NODE_ENV === "production")
//   baseURL = "https://aws.brainquire.com";

export { baseURL, programLink, jobImageLink, inventoryURL, infoURL };
