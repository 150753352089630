import axios from '../../config/axios-instance';
import api from '../../config/api';
import { showNotification } from "./ui";
import {
    CORPORATION_DETAILS_REQUEST,
    CORPORATION_DETAILS_SUCCESS,
    CORPORATION_DETAILS_FAIL,
    GET_DIV_NAMES_AND_COUNTS_REQUEST,
    GET_DIV_NAMES_AND_COUNTS_SUCCESS,
    GET_DIV_NAMES_AND_COUNTS_FAIL,
    GET_DIVS_AND_USERS_REQUEST,
    GET_DIVS_AND_USERS_SUCCESS,
    GET_DIVS_AND_USERS_FAIL,
    GET_CORPORATION_RESULTS_REQUEST,
    GET_CORPORATION_RESULTS_SUCCESS,
    GET_CORPORATION_RESULTS_FAIL,
    GET_CORPORATION_USER_RESULTS_REQUEST,
    GET_CORPORATION_USER_RESULTS_SUCCESS,
    GET_CORPORATION_USER_RESULTS_FAIL,
} from "./actionTypes";


const getCorporationUserResultsRequest = () => {
    return {
        type:GET_CORPORATION_USER_RESULTS_REQUEST
    }
}

const getCorporationUserResultsSucces = (users_results) => {
    return {
        type:GET_CORPORATION_USER_RESULTS_SUCCESS,
        users_results
    }
}

const getCorporationUserResultsFail = () => {
    return {
        type:GET_CORPORATION_USER_RESULTS_FAIL
    }
}

export const getCorporationUserResults = (id,type ) => {
    return (dispatch,getState) => {
        dispatch(getCorporationUserResultsRequest())
        return axios.get("tests/corp_user_results",{params:{id,type,token:getState().auth.token}})
            .then(response=> {
                dispatch(getCorporationUserResultsSucces(response.data));
                return response.data;
            })
            .catch(error=>{
                dispatch(getCorporationUserResultsFail());
                dispatch(showNotification(error.message));
            })
    }
}



const getCorporationResultsRequest = () => {
    return {
        type:GET_CORPORATION_RESULTS_REQUEST
    }
}

const getCorporationResultsSucces = () => {
    return {
        type:GET_CORPORATION_RESULTS_SUCCESS
    }
}

const getCorporationResultsFail = () => {
    return {
        type:GET_CORPORATION_RESULTS_FAIL
    }
}

export const getCorporationResults = (id,type ) => {
    return (dispatch,getState) => {
        dispatch(getCorporationResultsRequest())
        return axios.get(api.get_corporation_results,{params:{id,type,token:getState().auth.token}})
            .then(response=> {
                dispatch(getCorporationResultsSucces());
                return response.data;
            })
            .catch(error=>{
                dispatch(getCorporationResultsFail());
                dispatch(showNotification(error.message));
            })
    }
}



const getCorporationDetailsRequest = () => {
    return {
        type:CORPORATION_DETAILS_REQUEST
    }
}

const getCorporationDetailsSuccess = () => {
    return {
        type:CORPORATION_DETAILS_SUCCESS
    }
}

const getCorporationDetailsFail = () => {
    return {
        type:CORPORATION_DETAILS_FAIL
    }
}

export const getCorporationDetails = corporation_id => {
    return (dispatch,getState) => {
        dispatch(getCorporationDetailsRequest())
        return axios.get(api.get_corporation_details,{params:{corporation_id,token:getState().auth.token}})
            .then(response=> {
                dispatch(getCorporationDetailsSuccess());
                return response.data;
            })
            .catch(error=>{
                dispatch(getCorporationDetailsFail());
                dispatch(showNotification(error.message));
            })
    }
}

const getDivNamesAndCountsRequest = () => {
    return {
        type: GET_DIV_NAMES_AND_COUNTS_REQUEST
    }
}
const getDivNamesAndCountsSuccess = corporation => {
    return {
        type: GET_DIV_NAMES_AND_COUNTS_SUCCESS,
        corporation
    }
}
const getDivNamesAndCountsFail = () => {
    return {
        type: GET_DIV_NAMES_AND_COUNTS_FAIL
    }
}

export const getDivNamesAndCounts = corporation_id => {
    return (dispatch,getState) => {
        dispatch(getDivNamesAndCountsRequest())
        return axios.get(api.get_div_names_and_counts, 
            {
                params:{
                    corporation_id,
                    token:getState().auth.token
                }
            }).then(response => {
                dispatch(getDivNamesAndCountsSuccess())
                return response.data
            }).catch(error => {
                dispatch(getDivNamesAndCountsFail())
                dispatch(showNotification(error.message))
            })
    }
}

const getDivsAndUsersRequest = () => {
    return {
        type: GET_DIVS_AND_USERS_REQUEST
    }
}
const getDivsAndUsersSuccess = (data) => {
    return {
        type: GET_DIVS_AND_USERS_SUCCESS,
        data,
    }
}
const getDivsAndUsersFail = () => {
    return {
        type: GET_DIVS_AND_USERS_FAIL
    }
}


export const getDivsAndUsers = () => {
    return (dispatch,getState) => {
        dispatch(getDivsAndUsersRequest());
        return axios.get(api.get_divs_and_users, {params: {
            token: getState().auth.token}
        }).then(response=>{
            dispatch(getDivsAndUsersSuccess(response.data))
            return response.data
        }).catch(error=>{
            dispatch(getDivsAndUsersFail());
            dispatch(showNotification(error.message));
        })
    }
}


