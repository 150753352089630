import React from 'react';
import { Grid, Image } from 'semantic-ui-react';
import { Link as NavLink } from 'react-router-dom';
import { links } from '../../config/constants';
import './style.css';
import { withNamespaces } from 'react-i18next';
import { sizeHeight } from '@material-ui/system';


const IOSMarketBadge = '/assets/images/appstore.png';
const GoogleMarketBadge = '/assets/images/googleplay.png';
const Instagram = '/assets/images/insta.png';
const Facebook = '/assets/images/face.png';
const Twitter = '/assets/images/twitter.png';
const Mention = '/assets/images/mention.png';
const Web = '/assets/images/web.png';

const sign = (<div style={{ margin: "auto", width: "fit-content", display:"flex" }} >
  <Image src="/assets/images/hal-logo.png" floated="left"/>
  <div style={{ borderLeft: "1px solid #7f7f7f", display: "inline-block",marginLeft: ".7em", paddingLeft: ".7em", fontSize: ".9em"}}>
    <span> ODTÜ-Halıcı Yazılımevi</span> <br />
    <span>Teknokent, ODTÜ</span> <br />
    <a href={links.halici} target="_blank" className="no-style" rel="noopener noreferrer"> <b>www.halici.com.tr</b> <br /></a>
  </div>
</div>)


export default withNamespaces()(({ user }) =>
  <div style={{
    backgroundColor: "white",
    boxShadow: "0px -3px 12px -1px rgba(138,136,138,1)",
    position: "relative"
  }} className="Footer py-1">

    <Grid container
      style={{ marginTop: 0, borderRadius: 0 }}
      verticalAlign="middle"
      centered
      stackable>
      <Grid.Column only="computer" width="5" style={{ paddingLeft: 0 }}>
        <div style={{ float: "left" }}>
          {sign}
        </div>
      </Grid.Column>
      <Grid.Column computer="6" tablet="8">
        <div style={{ textAlign: "left", display: "flex", width: "100%", flexWrap: "wrap", justifyContent: "center", alignItems: "center" }}>
          <NavLink style={{ width: "47%", color: "black", padding: "5px 0", margin: 0, marginLeft: "3%", textAlign: "center" }} to="/info"><b>Meslekİlgi Modülü</b> Nedir?</NavLink>
          {/* <NavLink style={{ width: "47%", color: "black", padding: "5px 0", margin: 0, marginLeft: "3%", textAlign: "center" }} to="/how-much">Ne Kadar?</NavLink> */}
          <NavLink style={{ width: "47%", color: "black", padding: "5px 0", margin: 0, marginLeft: "3%", textAlign: "center" }} to="/who-can-use">Kimler Kullanabilir?</NavLink>
          
          {user && <NavLink style={{ width: "47%", color: "black", padding: "5px 0", margin: 0, marginLeft: "3%", textAlign: "center" }} to="/search-program">Meslek Ara?</NavLink>}
          
          <NavLink style={{ width: "47%", color: "black", padding: "5px 0", margin: 0, marginLeft: "3%", textAlign: "center" }} to="/membership">Üyelik</NavLink>

          <NavLink style={{ width: "47%", color: "black", padding: "5px 0", margin: 0, marginLeft: "3%", textAlign: "center" }} to="/faq">S.S.S</NavLink>
        </div>
      </Grid.Column>
      <Grid.Column verticalAlign="middle" computer="5" tablet="8" style={{ paddingRight: 0 }}>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
          <a href={links.ios} target="_blank" rel="noopener noreferrer">
            <Image src={IOSMarketBadge} width="100%"fluid/>
          </a>
          <a href={links.android} target="_blank" rel="noopener noreferrer" style={{ marginLeft: "1em" }}>
            <Image src={GoogleMarketBadge} width="100%" fluid />
          </a>
        </div>

        <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", marginTop: "1em", lineHeight: "100%" }}>
          <a href={links.instagram} target="_blank" rel="noopener noreferrer">
            <Image src={Instagram} style={{ marginRight: "1em" }} size='mini' />
          </a>
          <a href={links.facebook} target="_blank" rel="noopener noreferrer">
            <Image src={Facebook} style={{ marginRight: "1em" }} size='mini' />
          </a>
          <a href={links.twitter} target="_blank" rel="noopener noreferrer">
            <Image src={Twitter} style={{ marginRight: "1em" }} size='mini'/>
          </a>
          <a href={links.griceviz} target="_blank" rel="noopener noreferrer">
            <Image src={Mention} fluid />
          </a>
          <a href={links.griceviz} target="_blank" rel="noopener noreferrer" style={{ borderLeft: "1px solid #7f7f7f", fontWeight: "bold", marginLeft: ".7em", paddingLeft: ".7em" }}>
            <Image src={Web} fluid />
          </a>

        </div>
      </Grid.Column>
      <Grid.Column width={16} only="tablet mobile">
        {sign}
      </Grid.Column>
    </Grid>

  </div>
);