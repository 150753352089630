import {
  GET_RESULTS_SUCCESS,
  SUBMIT_TEST_SUCCESS,
  SUBMIT_JOBS_SUCCESS,
  SIGN_OUT,
  RESET_TEST_SUCCESS,
  GET_CORPORATION_USER_RESULTS_SUCCESS
} from "../actions/actionTypes";

const initialState = {
  scores: [],
  programs: [],
  code: "",
  dsc: [],
  count: 0,
  user: {
    first_name: "",
    last_name: ""
  },
  corp_user_results:{},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_RESULTS_SUCCESS:
      return {
        ...state,
        scores: action.results.scores,
        programs: action.results.jobs,
        code: action.results.code.code,
        user: action.results.user || state.user,
        dsc: action.results.code.dsc,
        count: action.results.count
      };
    case SUBMIT_TEST_SUCCESS:
      return {
        ...state,
        scores: action.results.scores,
        programs: action.results.jobs.jobs.map(item => item.job),
        code: action.results.jobs.hash.code,
        user: action.results.user || state.user
      };
    case SUBMIT_JOBS_SUCCESS: {
      return {
        ...state,
        programs: action.jobs.map(item => item.job)
      };
    }
    case GET_CORPORATION_USER_RESULTS_SUCCESS:
      return {
        ...state,
        corp_user_results: action.users_results
      };
    case SIGN_OUT:
      return initialState;
    case RESET_TEST_SUCCESS:
      return initialState;
    default:
      return state;
  }
};
