import {createStore,combineReducers,compose,applyMiddleware} from 'redux';
import thunk from 'redux-thunk';

import authReducer from './reducers/auth';
import loadingReducer from './reducers/loading';
import notificationReducer from './reducers/notification';
import testsReducer from './reducers/tests';
import resultsReducer from './reducers/results';

const rootReducerFn = combineReducers({
    auth:authReducer,
    loading:loadingReducer,
    notifications:notificationReducer,
    tests:testsReducer,
    results:resultsReducer
});

const rootReducer = (state, action) => {
    // User login olduğunda store'daki tüm veriler initial state'e çekilmesi lazım. Aksi halde eğer daha önce bir user login-logout olduysa ondan kalan eski veriler store'da kalıyor
    // login olduğumuzda state'i initial value'lara set etmeye zorlamak için logout olurken state = undefined yazmak yeterli oluyor.
    if (action.type === 'SIGN_OUT') {
      state = undefined
    }
  
    return rootReducerFn(state, action)
  }

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default () =>  createStore(rootReducer,composeEnhancers(applyMiddleware(thunk)));
