import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import i18next from './config/i18next';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import { setInterceptors } from './config/axios-instance';

import configureStore from './store/configureStore';
import { autoSignIn } from './store/actions';

import 'semantic-ui-css/semantic.min.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './assets/styles/slick-custom.css';
import 'video-react/dist/video-react.css'


import * as serviceWorker from './serviceWorker';

const store= configureStore();
store.dispatch(autoSignIn());
setInterceptors(store);

const app = (
    <I18nextProvider i18n={i18next}>
        <BrowserRouter>
            <Provider store={store}>
            <Switch>
                    
                    <Route path="/" component={App} />

            </Switch>
            </Provider>
        </BrowserRouter>
    </I18nextProvider>
);
ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
