import React, {Component} from 'react';
import {withNamespaces} from 'react-i18next';
import LoginWrapper from '../../hoc/LoginWrapper/LoginWrapper';
import {connect} from 'react-redux';
import {Button, Header, Form} from "semantic-ui-react";
import {resetPassword} from '../../store/actions/auth';

class ResetPassword extends Component {
  state = {
    email: this.props.match.params.email,
    validationCode: this.props.match.params.validation_code,
    password: '',
    passwordValidate: '',
    resetSuccesful: false,
    error: false
  }

  changeHandler = (e) => {
    e.preventDefault();
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  submit = () => {
    const {validationCode, email, password, passwordValidate} = this.state;
    if(password.length > 5 && password === passwordValidate) this.props.resetPassword({validationCode, email, password})
    else this.setState({error: true})
  }

  render(){
    const {loading, t} = this.props;
    const {password, passwordValidate, error} = this.state;
    return(
      <LoginWrapper loading = {loading}>
        <Form size="large" style = {{maxWidth: '25em', margin: 'auto', marginTop: '20%'}}>
          <Header as='h4' color='grey'>{t('reset_password')}</Header>
          <Form.Input
            className="rounded"
            fluid
            icon="lock"
            iconPosition="left"
            placeholder={t('password')}
            name="password"
            type="password"
            onChange={this.changeHandler}
            value={password}
            error={error}
          />
          <Form.Input
            className="rounded"
            fluid
            icon="lock"
            name="passwordValidate"
            iconPosition="left"
            placeholder={t('password_re')}
            type="password"
            onChange={this.changeHandler}
            value={passwordValidate}
            error={error}
          />
          <Button
            className="rounded"
            fluid
            secondary
            size="large"
            onClick={this.submit}
            style={{backgroundColor: "#38BA9B"}}
          >
            {t('save')}
          </Button>
        </Form>
      </LoginWrapper>
    )
  }
}

const mapStateToProps = state => {
  return {
    loading: state.loading["RESET_PASSWORD"] ,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    resetPassword: (creds) => dispatch(resetPassword(creds))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(ResetPassword));
