
import {SIGN_IN_SUCCESS,SIGN_OUT,POLICY_AGREED,SIGN_UP_SUCCESS,EDIT_USER_SUCCESS,GET_USER_DETAILS_SUCCESS, SET_USER_DETAILS_SUCCESS} from '../actions/actionTypes';

const initialState = {
    isPolicyAgreed: false,
    user:null,
    token:null,
    details:{}
}

const authReducer = (state=initialState,action) =>{
    switch(action.type){
        case SIGN_IN_SUCCESS:
        return {
            ...state,
            user:action.user,
            token:action.token
        }
        case SIGN_OUT:
        return initialState;
        case SIGN_UP_SUCCESS:
        return {
            ...state,
            user:action.user,
            token:action.token
        }
        case POLICY_AGREED:
        return {
          ...state,
          isPolicyAgreed: true
        }
        case EDIT_USER_SUCCESS:
        return {
          ...state,
          user: {
            ...state.user,
            ...action.user
          }
        }
        case GET_USER_DETAILS_SUCCESS:
        let details = {};
        action.details.forEach(detail => {
          details[detail.user_detail_key_uid] = detail.value;
        })
        return {
          ...state,
          details
        }
        case SET_USER_DETAILS_SUCCESS:
          let sentDetails =action.details.reduce((acc,detail)=> {
            Object.keys(detail).forEach(key=>{
              acc[key] = detail[key]
            });
            return acc;
          },{} );
          return {
            ...state,
            details:{
              ...state.details,
              ...sentDetails
            }
          }
        default:
        return state;
    }
}

export default authReducer;
