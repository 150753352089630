import React, { Component } from "react";
import { Grid, Segment, Header, Image, Icon } from 'semantic-ui-react';
import TypeCodeLetter from "../Sections/TypeCode/TypeCodeLetter"
import { withNamespaces } from 'react-i18next';
import { connect } from "react-redux";
import { typeColor } from '../../../helpers/typeColor';
import './style.css';
import Report from "../../../containers/Report/Report";
import { printWithZip } from "../../../helpers/pdfWithZip";
import { print } from "../../../helpers/pdf";
import { autoSignIn } from '../../../store/actions';
import configureStore from '../../../store/configureStore';
import { setInterceptors } from '../../../config/axios-instance';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { inventoryURL } from '../../../config/environment';


import {
    Card,
    Typography,
    IconButton,
    Table,
    thead,
    tbody,
    td,
    tr,
    CircularProgress,
    Collapse,
  } from "@material-ui/core";
import {getCorporationUserResults} from "../../../store/actions/corporation"
import JSZip from 'jszip'

const codeTypes = {
  S: "social",
  C: "conventional",
  E: "enterprising",
  I: "investigative",
  R: "realistic",
  A: "artistic"
};

class UserList extends Component {
   printAll = async  (users,token) => {
    const { corporation_id,type,division_id } = this.props.match.params;
    /* if(type=="corporation"){
      await this.props.getCorporationUserResults(corporation_id,type)
    }
    else if(type=="division"){
      await this.props.getCorporationUserResults(division_id,type)
    } 
    console.log(this.props)
    var zip=new JSZip();
    console.log(this.props.mode)
    var mode = { autoPrint: false };
    this.setState({ mode, converting: true });*/
    /* printWithZip(
      zip,
      mode = { autoPrint: false },
      ["report"],//,"page-1", "page-2"],
      "Pelin Kılıç"
      //`${user.first_name} ${user.last_name}`
    ) */
    /* print(
      mode = { autoPrint: false },
      ["report"],//,"page-1", "page-2"],
      "Pelin Kılıç"
    ) */
    
   /* for(var user of users){
    //users.forEach(user => {
      console.log(user.id)
      
       const store= configureStore();
      store.dispatch(autoSignIn());
      setInterceptors(store); */
     // ReactDOM.render(  <TypeCodeLetter letter="A" key={1} />,document.getElementById('meslekilgi-corporation-report')
     //this.setState({printAll:true})
      /* ReactDOM.render( 
      
        <Provider store={store}>
       
        
      <Report user_id={user.id} 
        scores={this.props.corp_user_results.scores || null}
        code={ this.props.corp_user_results.code  || null} 
        dsc={ this.props.corp_user_results.dsc   || null}
        programs= {this.props.corp_user_results.programs || null} 
        user= {this.props.corp_user_results.user  || null}
        count= {this.props.corp_user_results.count  || null}
      style={{display:"none"}}></Report>
       </Provider>
       ,document.getElementById('meslekilgi-corporation-report')) */
      //await window.open(`http://localhost:8001/report/${user.id}?token=${token}`,"Corporation"+user.id.toString())
    //}
    //});
  };
  render(){
    const { data, t, letter ,users ,token, print, loading, showPrintButtons}=this.props
    console.log(this.props)
    let id=0
     return (
  <Grid.Column style={{width:"1200px",height:"auto",pageBreakBefore:"always"}}>
    {/* <Segment style={{display:"flex",height:"70px"}}>
    
    { <Header id="header" textAlign="left" floated= "left" className="ReportHeader left" style={{fontFamily:"Open Sans",fontWeight:900}} size="large">
       Katılımcı Öğrenciler
       </Header>
      {showPrintButtons && 
      <Header id="downloadAll" floated='right' icon="file pdf outline" content="Tümünü İndir"   
      style={{fontFamily:"Open Sans",fontWeight:900, textAlign:"right", justifyContent:"right"}}    
      onClick={ async  () => this.printAll(users,token)}/>} }
       
    </Segment> */}
    
    <table id="user-list" class="UserList" width="100%" style={{ tableLayout: "auto", pageBreakInside:"auto"}}>
          <colgroup>
            <col style={{ width: "45%", }} />
            <col style={{ width: '18%' }} />
            <col style={{ width: '10%' }} />
            <col style={{ width: '10%' }} />
            <col style={{ width: '17%' }} />
          </colgroup>
          <col width="40%"></col>
          <col width="20%"></col>
          <col width="13%"></col>
          <col width="10%"></col>
          <col width="17%"></col>
          <thead>
            <tr
              style={{
                backgroundColor: "#eee",
                borderRadius: 20,
                height: "100px",
                borderStyle: 'solid',
              }}
            >
              
              <td  style={{ borderTopWidth: 0, height: "initial", textAlign: "left", fontSize:28, 
                borderStyle: 'solid',
                borderBottomWidth: 0,
                borderLeftWidth: 0,
                borderRightWidth: 0,}}>
                Adı
              </td>
              <td  style={{ borderTopWidth: 0, height: "initial", textAlign: "left", fontSize:28,
                borderStyle: 'solid',borderBottomWidth: 0,
                borderLeftWidth: 0,
                borderRightWidth: 0, }}>
                Kişilik Tipi
              </td>
              <td  style={{ borderTopWidth: 0, height: "initial", textAlign: "left", fontSize:28, 
                borderStyle: 'solid',borderBottomWidth: 0,
                borderLeftWidth: 0,
                borderRightWidth: 0, }}>
                Sınıfı
              </td>
              { <td style={{ borderTopWidth: 0, height: "initial", textAlign: "left", fontSize:28, 
                borderStyle: 'solid',borderBottomWidth: 0,
                borderLeftWidth: 0,
                borderRightWidth: 0,}}>
                Yaş
              </td>}
              {<td  style={{ borderTopWidth: 0, height: "initial", textAlign: "left", fontSize:28,
                borderStyle: 'solid',borderBottomWidth: 0,
                borderLeftWidth: 0,
                borderRightWidth: 0, }}>
                Tarih 
              </td>}
            </tr>
          </thead>
          <tbody>
        
          {users.map((user, index) => {
            if(index%10==0){
              id=id+1
            }
            return (
              <tr id={id} key={index} style={{
                borderRadius: 20,
                borderStyle: 'solid',
                height:"100px",
                pageBreakInside: "avoid !important"
              }}> 
                <td style={{textAlign: "left",fontSize:16, borderStyle: 'solid',
                borderTopWidth: 0,
                borderBottomWidth: 0,
                borderLeftWidth: 0,
                borderRightWidth: 0,}} >
                  {
                    <a href={`${inventoryURL}/report/${user.id}?token=${token}`} 
                    style={{ color: "#7B7B7B", fontWeight: "bold" }}
                    target="_blank" rel="noopener noreferrer">{user.name + "   "}</a>
                  }
                  {showPrintButtons&&
                  <Icon size="small" floated="left" name="file pdf outline" onClick={ async () => {
                    window.open(`${inventoryURL}/report/${user.id}?token=${token}`,user.id.toString())}}/>}
                </td >
                <td align="right" style={{ justifyContent:"left",fontSize:16, fontWeight:"bold",textAlign: "left", borderStyle: 'solid',borderTopWidth: 0,
                borderBottomWidth: 0,
                borderLeftWidth: 0,
                borderRightWidth: 0,}}>
                  {
                    user.code.code && user.code.code.map((letter, index) => {
                      if(letter!="," && index!=user.code.code.length-1){
                        return <span style={{color:typeColor(codeTypes[letter])}}>{t("types."+codeTypes[letter]+".name")[0]},</span>
                      }
                      if(index===user.code.code.length-1 && letter!=","){
                        return <span style={{color:typeColor(codeTypes[letter])}}>{t("types."+codeTypes[letter]+".name")[0]}</span>
                      }
                    })
                  }
                 {  <div className=" TypeCodeLetterUserList" style={{display:"flex",justifyContent:"right", textAlign: "right"}}>
                  {/*  user.code.code &&
                    user.code.code.map((letter,letter_index) => {
                      if(letter!=","){
                        return (
                          <TypeCodeLetter letter={letter} key={letter_index} />
                        )
                      }
                    }) */
                  }
                  </div>  }
                   {!user.code.code &&
                    "Test sonucu bulunamadı."
                  }
                </td>
                <td style={{textAlign: "left",fontSize:16, borderStyle: 'solid',borderTopWidth: 0,
                borderBottomWidth: 0,
                borderLeftWidth: 0,
                borderRightWidth: 0,}}>
                    {user.division}
                </td>
                {<td style={{textAlign: "left",fontSize:16, borderStyle: 'solid',borderTopWidth: 0,
                borderBottomWidth: 0,
                borderLeftWidth: 0,
                borderRightWidth: 0,}} >
                 {user.birthdate && new Date().getFullYear()- (+user.birthdate.substring(0,4))}
                 {!user.birthdate && "-"}
                 </td>}
                { <td style={{textAlign: "left",fontSize:16, borderStyle: 'solid',borderTopWidth: 0,
                borderBottomWidth: 0,
                borderLeftWidth: 0,
                borderRightWidth: 0,}}>
                  {user.test_at && user.test_at.substring(0,10)}
                  {!user.test_at && "-"}
                </td>}
              </tr>
            )
          }) } 
          </tbody>
        </table>
  </Grid.Column>

)}};

const mapStateToProps = state => {
  return {
    corp_user_results: state.results.corp_user_results
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getCorporationUserResults: (corporation_id,type) => dispatch(getCorporationUserResults(corporation_id,type)),
  };
};


export default connect(mapStateToProps,
  mapDispatchToProps
) (withNamespaces()(UserList))