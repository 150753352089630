import React, { Component } from "react";
import { Radar } from "react-chartjs-2";
import { Segment, Header } from "semantic-ui-react";

export default class ResultRadarChart extends Component {
    componentDidMount(){
        this.refs.chart.chartInstance.aspectRatio =1.7;
        //window.chartInstance = this.refs.chart.chartInstance;
    }
    render() {
        const { data } = this.props;
        
        const chartData = {
            labels: data.map((item, i) => {
                if(i===0) return "   "+item.name;
                if(i===3) return item.name+"   ";
                return item.name}),
            datasets: [
                {
                    data: data.map(item => +item.value.toFixed(2)),
                    backgroundColor: "rgba(162,212,225,.6)",
                    label: "first",
                    pointRadius:0,
                    borderColor:"#36495D",
                    borderWidth:1.5

                },
                {
                    data: data.map(item => Math.max(...data.map(item => 60))),
                    backgroundColor: "rgba(255,255,255,0)",
                    label: "second",
                    pointRadius: 9,
                    pointHoverRadius: 9,
                    pointStyle: "rectRounded",
                    pointRotation: 45,
                    pointHitRadius: 0,
                    pointBackgroundColor: data.map(item => item.color),
                    borderColor: "transparent"
                }
            ]
        };   
        const options = {
            defaultFontFamily: "Open Sans",
            startAngle:90,
            elements: {
                line: {
                    borderJoinStyle: "round"
                }
            },            
            legend: {
                display: false
            },
            scale: {
                pointLabels: {
                    fontSize: 15,
                    lineHeight:2,
                    fontColor:data.map(item=> item.color),
                },
                angleLines: {
                    display: true,
                },
                gridLines:{},
                ticks: {
                    display: false,
                    max: 60,
                    min:0
                }
            },
            tooltips: {
                enabled: false
            }
        };
        return (
            <Segment id={this.props.id} style={{display:"flex", flexDirection:"column"}}>
                <Header className="ReportHeader" size="large" content="Genel Kişilik Tipi Dağılımı" />
                <div style={{flexGrow:1,display:"flex",justifyContent:"center",alignItems:"center", fontWeight:"bold"}}>
                    <Radar data={chartData} options={options} ref='chart' aspectRatio={1}/>
                </div>
            </Segment>);
    };

}