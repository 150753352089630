import {
  SHOW_NOTIFICATION,
  HIDE_NOTIFICATION,
  SIGN_OUT
} from "../actions/actionTypes";
const initialState = [];
let id = 0;
// notification {message:"Error sgdsgff" , type :"info"|| "error" || "danger",dismiss:false}
const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_NOTIFICATION:
      return [...state, {
        id: id++,
        ...action.notification
      }];
    case HIDE_NOTIFICATION:
      return state.map(notification => {
        if (notification.id === action.notificationIndex)
          return {
            ...notification,
            dismiss: true
          };
        return notification;
      });
    case SIGN_OUT:
    return initialState;
    default:
      return state;
  }
};

export default notificationReducer;