import React, { PureComponent } from 'react';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { Grid, Card, Image, Icon, Message } from 'semantic-ui-react';
import { Redirect } from 'react-router-dom';
import { submitJobs } from '../../store/actions';
import _ from 'lodash';
import './style.css';
import { jobImageLink } from '../../config/environment';

class Profession extends PureComponent {
  state = {
    required: 5,
    denyLimit: 3,
    index: 0,
    jobs: []
  };


  componentDidMount() {
    this.setState({
      jobs: this.props.programs.map(program => ({
        id: program.id,
        name: program.name,
        deny: 0,
        yokatlas:program.yokatlas
      }))
    })
  }
  onClick = (denied) => {
    let { denyLimit, index } = this.state;
    let newIndex;
    let jobs = [...this.state.jobs];
    let newJob = { ...jobs[denied], deny: jobs[denied].deny + 1 }
    jobs[denied] = newJob;
    jobs = jobs.filter(job => job.deny < denyLimit)

    if (index + 2 >= jobs.length) {
      newIndex = 0;
      jobs = _.shuffle(jobs);
    } else newIndex = index + 2;
    this.setState({ jobs, index: newIndex }, () => {
      if (jobs.length <= this.state.required) this.submitJobs();
    })
  }
  submitJobs = () => {
    return this.props.submitJobs(this.state.jobs.map(job => job.id))
    // .then(res=>{
    //   if(res)
    //   this.props.history.push("/report/"+this.props.user_id);
    // });
  }

  getCard = (index, nextIndex, ribbonPos = true) => {
    const { jobs } = this.state;
    return (
      <Grid.Column className="justify-center">
        <Card className="iconHover" onClick={() => this.onClick(nextIndex)}>
          <Image
            fluid
            src={`${jobImageLink}/${jobs[index].yokatlas}.png`}
            width="290"
            height="203"
            onError = {e=>{e.target.onerror = null; e.target.src= "https://dummyimage.com/400x280/f5f5f5/b3b3b3.png&text=Resim+bulunamad%C4%B1"}}
          />
          <Card.Content>
            <Card.Header style={{ textAlign: 'center' }}>{jobs[index].name}</Card.Header>
            <Card.Meta className="my-1" style={{ textAlign: 'center' }}>
              <Icon name="heart outline" color='red' size='big' />
            </Card.Meta>
          </Card.Content>
        </Card>
      </Grid.Column>
    )
  }

  render() {
    const { t, test } = this.props;
    const { jobs, index } = this.state;
    // if (!test) return <Redirect to='/main' />
    if (!jobs[0]) return <div>Job not found</div>
    let nextIndex = (index + 1) % jobs.length
    const LeftCard = () => this.getCard(index, nextIndex);
    const RightCard = () => this.getCard(nextIndex, index, 'right');
    return (
      <>
        <Message
          warning
          icon='info circle'
          content={t('choose_jobs')}
        />
        <Grid padded style={{ flex: 1, padding: '4em' }} stackable columns={2} divided>
          <LeftCard />
          <RightCard />
        </Grid>
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    test: state.tests[0],
    programs: state.results.programs,
    submitJobsLoading: state.loading['SUBMIT_JOBS'],
    user_id: state.auth.user.id
  }
}

const mapDispatchToProps = dispatch => {
  return {
    submitJobs: (jobs) => dispatch(submitJobs(jobs))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(Profession));
