import React, { Component } from 'react';
import { Form, Button } from 'semantic-ui-react';
import { withNamespaces } from 'react-i18next';

class LoginForm extends Component {
  state = {
    email: "",
    password: ""
  };

  changeHandler = event => {
    const newVal = event.target.value.trim();
    if(event.target.name === 'email') this.props.emailHandler(newVal)
    this.setState({[event.target.name]: newVal});
  };

  signIn = () => {this.props.signIn(this.state).then(this.props.signInSucceeded)};

  render(){
    const {t} = this.props;
    return (
      <Form size="large"  >
        <Form.Input
          className="rounded"
          fluid
          icon="mail"
          iconPosition="left"
          placeholder={t('email')}
          name="email"
          onChange={this.changeHandler}
          value={this.state.email}
        />
        <Form.Input
          className="rounded"
          fluid
          icon="lock"
          name="password"
          iconPosition="left"
          placeholder={t('password')}
          type="password"
          onChange={this.changeHandler}
          value={this.state.password}
        />
        <Button
          className="rounded"
          fluid
          secondary
          size="large"
          onClick={this.signIn}
          style={{backgroundColor: "#38BA9B"}}
        >
          {t('login.login')}
        </Button>
    </Form>)
  }
}

export default withNamespaces()(LoginForm);
