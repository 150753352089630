import React from 'react';
import { Container, Grid, Image } from 'semantic-ui-react';

const landingBanner = () => (
  <div  className="Banner">
    <Container>
      <Grid columns={2} stretched stackable reversed="mobile">
      <Grid.Column width={4}>
          <div style={{ position: "relative" }}>
            <Image className='BannerImage' src='/assets/images/aklin-yolu.png' size="medium" />
          </div>
        </Grid.Column>
        <Grid.Column width={12}>
          <div style={{ display: "flex", alignItems: "center", padding: "2em 1em", zIndex: 1 }}>
            <div style={{ fontSize: "1.5em", lineHeight: 1.5 }}>
              <span>Meslekİlgi Modülü, </span>
              <span style={{ fontWeight: "bold" }}>Griceviz - Bilişsel Beceri ve Zihinsel Gelişim Platformu'</span>
              <span>nun bir parçasıdır.</span>
            </div>
          </div>
        </Grid.Column>

      </Grid>
    </Container>
  </div>
);

export default landingBanner;
